<template>
  <div class="contentwrapper">
      <div class="contentheadersub isFlex">
        <div class="isFlexGrow">Settings &amp; Account</div>
        <div>
          <b-switch v-model="Me.demo">
            <span v-if="Me.demo">Demo content on</span>
            <span v-else>Demo content off</span>
          </b-switch>
        </div>
      </div>
      <div class="isFlexGrow" style="position: relative;">
        <div class="contentsroll">
          <!-- content: start -->
          <div class="tile">
            <div class="tile is-4 p11 is-vertical">
              <div class="txth4 mb11">My Details</div>
              <!-- personal details: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Contact Details</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="First Name">
                      <b-input v-model="Me.firstname" expanded></b-input>
                    </b-field>
                    <b-field label="Last Name">
                      <b-input v-model="Me.lastname" expanded></b-input>
                    </b-field>
                    <b-field label="Business Name">
                      <b-input v-model="Me.coname" expanded></b-input>
                    </b-field>
                    <b-field label="E-mail">
                      <b-input v-model="Me.email" expanded></b-input>
                    </b-field>
                    <b-field label="Town">
                      <b-input v-model="Me.town" expanded></b-input>
                    </b-field>
                    <b-field label="City">
                      <b-input v-model="Me.city" expanded></b-input>
                    </b-field>
                    <b-field label="County/State/Province">
                      <b-input v-model="Me.county" expanded></b-input>
                    </b-field>
                    <b-field label="Postcode">
                      <b-input v-model="Me.postcode" expanded></b-input>
                    </b-field>
                    <b-field label="Country (Required)">
                      <b-select placeholder="Select a country" v-model="Me.country" expanded>
                        <option
                          v-for="(option, index) in countries"
                          :value="option.countryCode"
                          :key="index">
                          {{ option.label }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Prefered Currency">
                      <b-select placeholder="Select a country" v-model="Me.currency" expanded @input="setEx()">
                        <option
                          v-for="(cur, index) in Currencies"
                          :value="cur.code"
                          :key="index">
                          {{ cur.code }}: {{ cur.name }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Mobile">
                      <b-input v-model="Me.mobile" expanded></b-input>
                    </b-field>
                  </div>
                </div>
              </b-collapse>
              <!-- personal details: end -->
              <!-- profile: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Profile</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">
                    
                    <!-- <div class="tsk lgreybg isFlex mb11" v-if="Me.published === 'No'">
                      <div class="isFlexGrow mr11">Your profile is not visible to others</div>
                      <div><b-button size="is-small" @click="changeVisibility('Pending')">Make visible</b-button></div>
                    </div>
                    <div class="tsk lgreybg isFlex mb11" v-if="Me.published === 'Pending'">
                      <div class="isFlexGrow mr11">Your profile has been submitted for approval and can take up-to 72 hours to go live if approved.</div>
                      <div><b-button size="is-small" @click="changeVisibility('No')">Cancel</b-button></div>
                    </div>
                    <div class="tsk lgreybg isFlex mb11" v-if="Me.published === 'Yes'">
                      <div class="isFlexGrow mr11">Your profile is visible to others</div>
                      <div><b-button size="is-small" @click="changeVisibility('No')">Hide profile</b-button></div>
                    </div>
                    <div class="tsk lgreybg mb11" v-if="Me.published === 'Rejected'">
                      <div class="isFlex">
                        <div class="isFlexGrow mr11">Your profile has been rejected please correct the following:</div>
                        <div><b-button size="is-small" @click="changeVisibility('Pending')">Re-Submit</b-button></div>
                      </div>
                      <div>{{Me.publishedmessage}}</div>
                    </div> -->

                    <b-field label="Oneliner">
                      <b-input v-model="Me.oneliner" maxlength="125" type="textarea" rows="2" expanded placeholder="Tech entrepreneur with a passion for educating others"></b-input>
                    </b-field>

                    <div>
                      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                        <div class="menubar">
                          <div class="iconbutton m3 edbut" @click="commands.bold" :class="{ 'is-active': isActive.bold() }" ><font-awesome-icon :icon="['fal', 'bold']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.italic" :class="{ 'is-active': isActive.italic() }"><font-awesome-icon :icon="['fal', 'italic']" size="sm" ></font-awesome-icon></div>
                          <!-- <div class="iconbutton m3 edbut" @click="commands.strike" :class="{ 'is-active': isActive.strike() }" ><font-awesome-icon :icon="['fal', 'strikethrough']" size="sm" ></font-awesome-icon></div> -->
                          <div class="iconbutton m3 edbut" @click="commands.underline" :class="{ 'is-active': isActive.underline() }" ><font-awesome-icon :icon="['fal', 'underline']" size="sm" ></font-awesome-icon></div>
                          <!-- <div class="iconbutton m3 edbut" @click="commands.code" :class="{ 'is-active': isActive.code() }"><font-awesome-icon :icon="['fal', 'code']" size="sm"></font-awesome-icon></div> -->
                          <div class="iconbutton m3 edbut" @click="commands.heading({ level: 1 })" :class="{ 'is-active': isActive.heading({ level: 1 }) }" ><font-awesome-icon :icon="['fal', 'h1']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.heading({ level: 2 })" :class="{ 'is-active': isActive.heading({ level: 2 }) }" ><font-awesome-icon :icon="['fal', 'h2']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.heading({ level: 3 })" :class="{ 'is-active': isActive.heading({ level: 3 }) }" ><font-awesome-icon :icon="['fal', 'h3']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.bullet_list" :class="{ 'is-active': isActive.bullet_list() }" ><font-awesome-icon :icon="['fal', 'list-ul']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.ordered_list" :class="{ 'is-active': isActive.ordered_list() }" ><font-awesome-icon :icon="['fal', 'list-ol']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.todo_list" :class="{ 'is-active': isActive.todo_list() }" ><font-awesome-icon :icon="['fal', 'tasks']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.code_block" :class="{ 'is-active': isActive.code_block() }" ><font-awesome-icon :icon="['fal', 'code']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.horizontal_rule"><font-awesome-icon :icon="['fal', 'horizontal-rule']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.undo"><font-awesome-icon :icon="['fal', 'undo']" size="sm" ></font-awesome-icon></div>
                          <div class="iconbutton m3 edbut" @click="commands.redo"><font-awesome-icon :icon="['fal', 'redo']" size="sm" ></font-awesome-icon></div>
                        </div>
                      </editor-menu-bar>
                      <editor-content :editor="editor" style/>
                    </div>
                  </div>
                </div>
              </b-collapse>
              <!-- profile: end -->
              <!-- services: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Services offered</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div class="">
                      <div class="mt7 mb11">List all the service that you offer. One per line. Most important on top.</div>
                      <b-input placeholder="What problems can you help other overcome?"
                          v-model="textservices" icon-right="plus" icon-right-clickable
                          @icon-right-click="addText('textservices', Me.services)"
                          @keyup.native.enter="addText('textservices', Me.services);">
                      </b-input>
                      <div class="tsk lgreyborder p3 mt5">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="Me.services && Me.services.length === 0">No services defined</div>
                        <draggable tag="div" :list="Me.services" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in Me.services" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;">
                              <div class="iconbutton" @click="removeFrom(ti, Me.services)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
              <!-- services: end -->
              <!-- helpneeded: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Help needed</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div class="">
                      <div class="mt7 mb11">List all the areas you need help in or areas where you need a mentor of expert to guide you.</div>
                      <b-input placeholder="What problems do you need fixing?"
                          v-model="textservices" icon-right="plus" icon-right-clickable
                          @icon-right-click="addText('textservices', Me.helpneeded)"
                          @keyup.native.enter="addText('textservices', Me.helpneeded);">
                      </b-input>
                      <div class="tsk lgreyborder p3 mt5">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="Me.helpneeded && Me.helpneeded.length === 0">No help needed. You should become an expert!</div>
                        <draggable tag="div" :list="Me.helpneeded" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in Me.helpneeded" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;">
                              <div class="iconbutton" @click="removeFrom(ti, Me.helpneeded)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
              <!-- helpneeded: end -->
              <!-- projects: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Projects past and present</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <div v-if="showProjectEdit">
                      <div class="mt7 mb11">List projects you are working or have worked on in the past. </div>
                      <b-field label="Title of project">
                        <b-input placeholder="What problems do you need fixing?" v-model="textprojecttitle"></b-input>
                      </b-field>
                      <b-field label="A bit more detail about the project">
                        <b-input placeholder="Describe the project in less than 800 chars." v-model="textprojectdescription" type="textarea"  maxlength="800"></b-input>
                      </b-field>
                      <b-field label="Website?" message="Please enter a valid website url including the https:// or http://">
                        <b-input placeholder="https://..." v-model="textprojecturl"></b-input>
                      </b-field>
                      <b-field label="Link to a deck or presentation" message="Please enter a valid url including the https:// or http://">
                        <b-input placeholder="https://..." v-model="textprojectdeck"></b-input>
                      </b-field>
                      <b-field label="Your role in the project?" message="What did you do when you were part of the project?">
                        <b-input placeholder="Founder" v-model="textprojectrole" type="textarea" rows="2"  maxlength="200"></b-input>
                      </b-field>
                      <b-field label="Status of the project">
                        <b-select v-model="textprojectstatus" expanded>
                          <option value="Planning">Planning</option>
                          <option value="Active">Active</option>
                          <option value="Need help">Need help</option>
                          <option value="Raising Funds">Raising Funds</option>
                          <option value="Completed">Completed</option>
                        </b-select>
                      </b-field>
                      <div class="buttons">
                        <b-button type="is-info" expanded v-if="!showProjectEditYes" @click="addProject()">Add Project</b-button>
                        <b-button type="is-info" expanded v-if="showProjectEditYes" @click="saveProject()">Save Project</b-button>
                        <b-button type="is-light" expanded @click="showProjectEdit=false">Cancel</b-button>
                      </div>
                    </div>
                    <div v-if="!showProjectEdit">
                      

                      <div class="tsk lgreyborder p3 mt5">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="Me.projects && Me.projects.length === 0">No projects listed. Get busy!</div>
                        <draggable tag="div" :list="Me.projects" handle=".handle">
                          <div class="tsk p3 vlgreybg m3" v-for="(t, ti) in Me.projects" :key="ti">
                            <div class="isFlex">
                              <div class="isFlexGrow txtbold">{{t.title}}</div>
                              <div class="isFlex" style="min-width: 50px;">
                                <div class="iconbutton" @click="editProject(t, ti)"><font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                            <div class="txtsm">{{t.description | shorten(100) }} <span v-if="t.description.length > 100">...more</span></div>
                            <div class="txtsm mt5"><span class="txtbold">Role: </span>{{t.role }}</div>
                            <div class="txtsm"><span class="txtbold">Status: </span>{{t.status }}</div>
                            <div class="buttons mt7">
                              <a class="button is-small">Website</a>
                              <a class="button is-small">Deck/Presentation</a>
                            </div>
                          </div>
                          
                        </draggable>
                      </div>
                    </div>
                    <div v-if="!showProjectEdit">
                      <div class="buttons mt11">
                        <b-button type="is-info" expanded @click="showProjectEdit=true">Add a New Project</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
              <!-- projects: end -->
              <!-- expertise: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Expert</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">

                    <div class="tsk lgreybg mb11" v-if="Me.expert === 'No'">
                      <div >You are not listed as an expert. If you think you have what it takes give us a reason why you think you should be listed as an expert, list your areas of expertise and we will get in touch to get the process started.</div>
                      <div class="mt11">
                        <b-field label="Tell us why you think you should be listed as an expert">
                          <b-input placeholder="I should be an expert because..." type="textarea" v-model="Me.expertreason"></b-input>
                        </b-field>
                      </div>
                    </div>
                    
                    <div class="tsk lgreybg isFlex mb11" v-if="Me.expert === 'Pending'">
                      <div class="isFlexGrow mr11">Your profile has been submitted for approval as an expert and we will be intouch to discuss.</div>
                      <div><b-button size="is-small" @click="changeExpert('No')">Cancel</b-button></div>
                    </div>
                    <div class="tsk lgreybg isFlex mb11" v-if="Me.expert === 'Yes'">
                      <div class="isFlexGrow mr11">You are listed as an expert.</div>
                      <div><b-button size="is-small" @click="changeExpert('No')">Remove</b-button></div>
                    </div>
                    <div class="tsk lgreybg mb11" v-if="Me.expert === 'Rejected'">
                      <div class="isFlex">
                        <div class="isFlexGrow mr11">Your profile has been rejected please correct the following:</div>
                        <div><b-button size="is-small" @click="changeExpert('Pending')">Re-Submit</b-button></div>
                      </div>
                      <div>{{Me.publishedmessage}}</div>
                    </div>

                    <div class="">
                      <div class="mt7 mb11">What are you an expert in? In one liners, ordered by most proficient on top, least proficient at the bottom.</div>
                      <b-input placeholder="What problems can you help other overcome?"
                          v-model="textexpertise" icon-right="plus" icon-right-clickable
                          @icon-right-click="addText('textexpertise', Me.expertise)"
                          @keyup.native.enter="addText('textexpertise', Me.expertise);">
                      </b-input>
                      <div class="tsk lgreyborder p3 mt5">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="Me.expertise && Me.expertise.length === 0">No exertise points defined</div>
                        <draggable tag="div" :list="Me.expertise" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in Me.expertise" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;">
                              <div class="iconbutton" @click="removeFrom(ti, Me.expertise)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                    <div class="buttons mt11" v-if="Me.expert === 'No'"><b-button type="is-info" @click="changeExpert('Pending')" expanded>Apply to become an expert</b-button></div>
                  </div>
                </div>
              </b-collapse>
              <!-- expertise: end -->
              <!-- settings: start -->
              <b-collapse class="card" animation="slide" :open="false">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">Settings</p>
                  <a class="card-header-icon"><b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon></a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Hours in day">
                      <b-numberinput v-model="Me.dayhours"></b-numberinput>
                    </b-field>
                    <div class="txtbold mb5">Working days</div>
                    <div class="field">
                      <b-switch v-model="Me.sunday">Sunday</b-switch>
                    </div>
                    <div class="field">
                      <b-switch v-model="Me.monday">Monday</b-switch>
                    </div>
                    <div class="field">
                      <b-switch v-model="Me.tuesday">Tuesday</b-switch>
                    </div>
                    <div class="field">
                      <b-switch v-model="Me.wednesday">Wednesday</b-switch>
                    </div>
                    <div class="field">
                      <b-switch v-model="Me.thursday">Thursday</b-switch>
                    </div>
                    <div class="field">
                      <b-switch v-model="Me.friday">Friday</b-switch>
                    </div>
                    <div class="field">
                      <b-switch v-model="Me.saturday">Saturday</b-switch>
                    </div>

                    <!-- dividers: start -->
                    <div class="mb11">
                      <div class="txtbold redbg white roundedtop p7 isFlex">
                        <div class="isFlexGrow">Dividers</div>
                      </div>
                      <div class="lgreybg roundedbottom p7">
                        <div class="txtsm mt7 mb11">Dividers help you split your day in to sections. 
                          </div>
                        <b-input placeholder="e.g. Self promotion"
                            v-model="textdividers" icon-right="plus" icon-right-clickable
                            @icon-right-click="addTextDivider('textdividers', Me.dividers)" v-if="isEdit" @keyup.native.enter="addTextDivider('textdividers', Me.dividers);">
                        </b-input>
                        <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                          <div class="tsk p3 vlgreybg midgrey m3" v-if="Me.dividers && Me.dividers.length === 0">Nothing entered</div>
                          <draggable tag="div" :list="Me.dividers" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in Me.dividers" :key="ti">
                              <div class="isFlexGrow">
                                <contenteditable tag="div" :contenteditable="isEdit" v-model="t.text" :noNL="true" :noHTML="false"/>
                                <div class="isFlex mt3">
                                  <contenteditable tag="div" style="width: 70px; border: 1px solid #CCC; margin-right: 5px;" :contenteditable="isEdit" v-model="t.time" :noNL="true" />
                                  <b-checkbox >Alert</b-checkbox>
                                </div>
                              </div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, Me.dividers)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </div>
                    <!-- blockers: end -->
                  </div>
                </div>
              </b-collapse>
              <!-- settings: end -->
            </div>
            <div class="tile is-8 is-vertical p11">
              <div class="txth4 mb3">Profile Preview</div>
              <div class="mb11" v-if="!editUrl">
                <a :href="profileurl" target="_blank">{{ profileurl }}</a> 
                <b-button class="ml7" size="is-small" icon-left="edit" @click="editUrl=true">Change</b-button>
              </div>
              <div class="mb11 isFlex" v-if="editUrl">
                {{ profileurlpre }} 
                <b-input placeholder="handle" v-model="newhandle" @keyup.native="cleanurl();" size="is-small" style="width: 90px;"></b-input>
                <b-button class="ml7" size="is-small" icon-left="edit" @click="saveUrl">Save</b-button>
                <b-button class="ml7" size="is-small" icon-left="times" @click="editUrl=false"></b-button>
              </div>

              <div style="height: 20px;"></div>
              <!-- image: start -->
              <div class="isFlex isFlexJustify">
                <div class="isPointer" @click="changeImage()">
                  <img
                    src="/dummy/120x120.png"
                    class="thumb thumb-xlg thumb-circle"
                    v-if="Me.photo === ''"
                  />
                  <img
                    :src="Me.photo"
                    class="thumb thumb-xlg thumb-circle"
                    v-else
                  />
                </div>
              </div>
              <!-- image: end  -->
              <h3 class="txth2 txtc">
                {{ Me.firstname }} {{ Me.lastname }}
              </h3>
              <div class="p3 txtc">
                {{ Me.oneliner }}
              </div>
              
              <div class="ProfileDesc p3" v-html="Me.description"></div>
              
            </div>
          </div>
          <!-- content: end -->
        </div>
      </div>
      <div class="contentfooter isFlex vlgreybg p7">
        <div class="ml5 isFlexGrow">
          <!-- <b-button  type="is-info" rounded>Export</b-button>&nbsp;
          <b-button  type="is-info" rounded>Create PO</b-button>&nbsp;
          <b-button  type="is-info" rounded>Quote</b-button> -->
        </div>
        <div class="ml5">
          <b-button type="is-info" @click="saveSettings()">Save</b-button>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import ObjectID from "bson-objectid";
import { Utils } from '../mixins/utils.js';
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import ModalImage from "@/components/ModalImage.vue";
import { EventBus } from "../event-bus.js";
import array from "lodash/array";
import draggable from "vuedraggable";
// import ModalPay from "@/modals/ModalPay.vue";
import {
  Blockquote, // quote-right
  CodeBlock, // code
  Heading, // h1, h2, h3, h4
  HorizontalRule, // horizontal-rule
  OrderedList, // list-ol
  BulletList, // list-ul
  ListItem, //
  TodoItem,
  TodoList, // tasks
  Bold, // bold
  Code, // code ?
  Italic, // italic
  Link, // link
  Strike, // strikethrough
  Underline, //underline
  History // undo, redo
} from "tiptap-extensions";

export default {
  mixins: [Utils],
  name: 'blank',
  components: {
    EditorContent,
    EditorMenuBar,
    draggable
  },
  data() {
    return {
      Me: {},
      editor: null,
      countries: [
        { countryCode: 'GB', value: '44', label: 'UK (+44)' },
        { countryCode: 'US', value: '1', label: 'USA (+1)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: '', value: '', label: '---' },
        { countryCode: 'DZ', value: '213', label: 'Algeria (+213)' },
        { countryCode: 'AD', value: '376', label: 'Andorra (+376)' },
        { countryCode: 'AO', value: '244', label: 'Angola (+244)' },
        { countryCode: 'AI', value: '1264', label: 'Anguilla (+1264)' },
        { countryCode: 'AG', value: '1268', label: 'Antigua & Barbuda (+1268)' },
        { countryCode: 'AR', value: '54', label: 'Argentina (+54)' },
        { countryCode: 'AM', value: '374', label: 'Armenia (+374)' },
        { countryCode: 'AW', value: '297', label: 'Aruba (+297)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'AT', value: '43', label: 'Austria (+43)' },
        { countryCode: 'AZ', value: '994', label: 'Azerbaijan (+994)' },
        { countryCode: 'BS', value: '1242', label: 'Bahamas (+1242)' },
        { countryCode: 'BH', value: '973', label: 'Bahrain (+973)' },
        { countryCode: 'BD', value: '880', label: 'Bangladesh (+880)' },
        { countryCode: 'BB', value: '1246', label: 'Barbados (+1246)' },
        { countryCode: 'BY', value: '375', label: 'Belarus (+375)' },
        { countryCode: 'BE', value: '32', label: 'Belgium (+32)' },
        { countryCode: 'BZ', value: '501', label: 'Belize (+501)' },
        { countryCode: 'BJ', value: '229', label: 'Benin (+229)' },
        { countryCode: 'BM', value: '1441', label: 'Bermuda (+1441)' },
        { countryCode: 'BT', value: '975', label: 'Bhutan (+975)' },
        { countryCode: 'BO', value: '591', label: 'Bolivia (+591)' },
        { countryCode: 'BA', value: '387', label: 'Bosnia Herzegovina (+387)' },
        { countryCode: 'BW', value: '267', label: 'Botswana (+267)' },
        { countryCode: 'BR', value: '55', label: 'Brazil (+55)' },
        { countryCode: 'BN', value: '673', label: 'Brunei (+673)' },
        { countryCode: 'BG', value: '359', label: 'Bulgaria (+359)' },
        { countryCode: 'BF', value: '226', label: 'Burkina Faso (+226)' },
        { countryCode: 'BI', value: '257', label: 'Burundi (+257)' },
        { countryCode: 'KH', value: '855', label: 'Cambodia (+855)' },
        { countryCode: 'CM', value: '237', label: 'Cameroon (+237)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: 'CV', value: '238', label: 'Cape Verde Islands (+238)' },
        { countryCode: 'KY', value: '1345', label: 'Cayman Islands (+1345)' },
        { countryCode: 'CF', value: '236', label: 'Central African Republic (+236)' },
        { countryCode: 'CL', value: '56', label: 'Chile (+56)' },
        { countryCode: 'CN', value: '86', label: 'China (+86)' },
        { countryCode: 'CO', value: '57', label: 'Colombia (+57)' },
        { countryCode: 'KM', value: '269', label: 'Comoros (+269)' },
        { countryCode: 'CG', value: '242', label: 'Congo (+242)' },
        { countryCode: 'CK', value: '682', label: 'Cook Islands (+682)' },
        { countryCode: 'CR', value: '506', label: 'Costa Rica (+506)' },
        { countryCode: 'HR', value: '385', label: 'Croatia (+385)' },
        { countryCode: 'CU', value: '53', label: 'Cuba (+53)' },
        { countryCode: 'CW', value: '599', label: 'Curacao (+599)' },
        { countryCode: 'CY', value: '90392', label: 'Cyprus North (+90392)' },
        { countryCode: 'CY', value: '357', label: 'Cyprus South (+357)' },
        { countryCode: 'CZ', value: '42', label: 'Czech Republic (+42)' },
        { countryCode: 'DK', value: '45', label: 'Denmark (+45)' },
        { countryCode: 'DJ', value: '253', label: 'Djibouti (+253)' },
        { countryCode: 'DM', value: '1809', label: 'Dominica (+1809)' },
        { countryCode: 'DO', value: '1809', label: 'Dominican Republic (+1809)' },
        { countryCode: 'EC', value: '593', label: 'Ecuador (+593)' },
        { countryCode: 'EG', value: '20', label: 'Egypt (+20)' },
        { countryCode: 'SV', value: '503', label: 'El Salvador (+503)' },
        { countryCode: 'GQ', value: '240', label: 'Equatorial Guinea (+240)' },
        { countryCode: 'ER', value: '291', label: 'Eritrea (+291)' },
        { countryCode: 'EE', value: '372', label: 'Estonia (+372)' },
        { countryCode: 'ET', value: '251', label: 'Ethiopia (+251)' },
        { countryCode: 'FK', value: '500', label: 'Falkland Islands (+500)' },
        { countryCode: 'FO', value: '298', label: 'Faroe Islands (+298)' },
        { countryCode: 'FJ', value: '679', label: 'Fiji (+679)' },
        { countryCode: 'FI', value: '358', label: 'Finland (+358)' },
        { countryCode: 'FR', value: '33', label: 'France (+33)' },
        { countryCode: 'GF', value: '594', label: 'French Guiana (+594)' },
        { countryCode: 'PF', value: '689', label: 'French Polynesia (+689)' },
        { countryCode: 'GA', value: '241', label: 'Gabon (+241)' },
        { countryCode: 'GM', value: '220', label: 'Gambia (+220)' },
        { countryCode: 'GE', value: '7880', label: 'Georgia (+7880)' },
        { countryCode: 'DE', value: '49', label: 'Germany (+49)' },
        { countryCode: 'GH', value: '233', label: 'Ghana (+233)' },
        { countryCode: 'GI', value: '350', label: 'Gibraltar (+350)' },
        { countryCode: 'GR', value: '30', label: 'Greece (+30)' },
        { countryCode: 'GL', value: '299', label: 'Greenland (+299)' },
        { countryCode: 'GD', value: '1473', label: 'Grenada (+1473)' },
        { countryCode: 'GP', value: '590', label: 'Guadeloupe (+590)' },
        { countryCode: 'GU', value: '671', label: 'Guam (+671)' },
        { countryCode: 'GT', value: '502', label: 'Guatemala (+502)' },
        { countryCode: 'GN', value: '224', label: 'Guinea (+224)' },
        { countryCode: 'GW', value: '245', label: 'Guinea - Bissau (+245)' },
        { countryCode: 'GY', value: '592', label: 'Guyana (+592)' },
        { countryCode: 'HT', value: '509', label: 'Haiti (+509)' },
        { countryCode: 'HN', value: '504', label: 'Honduras (+504)' },
        { countryCode: 'HK', value: '852', label: 'Hong Kong (+852)' },
        { countryCode: 'HU', value: '36', label: 'Hungary (+36)' },
        { countryCode: 'IS', value: '354', label: 'Iceland (+354)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ID', value: '62', label: 'Indonesia (+62)' },
        { countryCode: 'IR', value: '98', label: 'Iran (+98)' },
        { countryCode: 'IQ', value: '964', label: 'Iraq (+964)' },
        { countryCode: 'IE', value: '353', label: 'Ireland (+353)' },
        { countryCode: 'IL', value: '972', label: 'Israel (+972)' },
        { countryCode: 'IT', value: '39', label: 'Italy (+39)' },
        { countryCode: 'JM', value: '1876', label: 'Jamaica (+1876)' },
        { countryCode: 'JP', value: '81', label: 'Japan (+81)' },
        { countryCode: 'JO', value: '962', label: 'Jordan (+962)' },
        { countryCode: 'KZ', value: '7', label: 'Kazakhstan (+7)' },
        { countryCode: 'KE', value: '254', label: 'Kenya (+254)' },
        { countryCode: 'KI', value: '686', label: 'Kiribati (+686)' },
        { countryCode: 'KP', value: '850', label: 'Korea North (+850)' },
        { countryCode: 'KR', value: '82', label: 'Korea South (+82)' },
        { countryCode: 'KW', value: '965', label: 'Kuwait (+965)' },
        { countryCode: 'KG', value: '996', label: 'Kyrgyzstan (+996)' },
        { countryCode: 'LA', value: '856', label: 'Laos (+856)' },
        { countryCode: 'LV', value: '371', label: 'Latvia (+371)' },
        { countryCode: 'LB', value: '961', label: 'Lebanon (+961)' },
        { countryCode: 'LS', value: '266', label: 'Lesotho (+266)' },
        { countryCode: 'LR', value: '231', label: 'Liberia (+231)' },
        { countryCode: 'LY', value: '218', label: 'Libya (+218)' },
        { countryCode: 'LI', value: '417', label: 'Liechtenstein (+417)' },
        { countryCode: 'LT', value: '370', label: 'Lithuania (+370)' },
        { countryCode: 'LU', value: '352', label: 'Luxembourg (+352)' },
        { countryCode: 'MO', value: '853', label: 'Macao (+853)' },
        { countryCode: 'MK', value: '389', label: 'Macedonia (+389)' },
        { countryCode: 'MG', value: '261', label: 'Madagascar (+261)' },
        { countryCode: 'MW', value: '265', label: 'Malawi (+265)' },
        { countryCode: 'MY', value: '60', label: 'Malaysia (+60)' },
        { countryCode: 'MV', value: '960', label: 'Maldives (+960)' },
        { countryCode: 'ML', value: '223', label: 'Mali (+223)' },
        { countryCode: 'MT', value: '356', label: 'Malta (+356)' },
        { countryCode: 'MH', value: '692', label: 'Marshall Islands (+692)' },
        { countryCode: 'MQ', value: '596', label: 'Martinique (+596)' },
        { countryCode: 'MR', value: '222', label: 'Mauritania (+222)' },
        { countryCode: 'YT', value: '269', label: 'Mayotte (+269)' },
        { countryCode: 'MX', value: '52', label: 'Mexico (+52)' },
        { countryCode: 'FM', value: '691', label: 'Micronesia (+691)' },
        { countryCode: 'MD', value: '373', label: 'Moldova (+373)' },
        { countryCode: 'MC', value: '377', label: 'Monaco (+377)' },
        { countryCode: 'MN', value: '976', label: 'Mongolia (+976)' },
        { countryCode: 'MS', value: '1664', label: 'Montserrat (+1664)' },
        { countryCode: 'MA', value: '212', label: 'Morocco (+212)' },
        { countryCode: 'MZ', value: '258', label: 'Mozambique (+258)' },
        { countryCode: 'MN', value: '95', label: 'Myanmar (+95)' },
        { countryCode: 'NA', value: '264', label: 'Namibia (+264)' },
        { countryCode: 'NR', value: '674', label: 'Nauru (+674)' },
        { countryCode: 'NP', value: '977', label: 'Nepal (+977)' },
        { countryCode: 'NL', value: '31', label: 'Netherlands (+31)' },
        { countryCode: 'NC', value: '687', label: 'New Caledonia (+687)' },
        { countryCode: 'NZ', value: '64', label: 'New Zealand (+64)' },
        { countryCode: 'NI', value: '505', label: 'Nicaragua (+505)' },
        { countryCode: 'NE', value: '227', label: 'Niger (+227)' },
        { countryCode: 'NG', value: '234', label: 'Nigeria (+234)' },
        { countryCode: 'NU', value: '683', label: 'Niue (+683)' },
        { countryCode: 'NF', value: '672', label: 'Norfolk Islands (+672)' },
        { countryCode: 'NP', value: '670', label: 'Northern Marianas (+670)' },
        { countryCode: 'NO', value: '47', label: 'Norway (+47)' },
        { countryCode: 'OM', value: '968', label: 'Oman (+968)' },
        { countryCode: 'PW', value: '680', label: 'Palau (+680)' },
        { countryCode: 'PA', value: '507', label: 'Panama (+507)' },
        { countryCode: 'PG', value: '675', label: 'Papua New Guinea (+675)' },
        { countryCode: 'PY', value: '595', label: 'Paraguay (+595)' },
        { countryCode: 'PE', value: '51', label: 'Peru (+51)' },
        { countryCode: 'PH', value: '63', label: 'Philippines (+63)' },
        { countryCode: 'PL', value: '48', label: 'Poland (+48)' },
        { countryCode: 'PT', value: '351', label: 'Portugal (+351)' },
        { countryCode: 'PR', value: '1787', label: 'Puerto Rico (+1787)' },
        { countryCode: 'QA', value: '974', label: 'Qatar (+974)' },
        { countryCode: 'RE', value: '262', label: 'Reunion (+262)' },
        { countryCode: 'RO', value: '40', label: 'Romania (+40)' },
        { countryCode: 'RU', value: '7', label: 'Russia (+7)' },
        { countryCode: 'RW', value: '250', label: 'Rwanda (+250)' },
        { countryCode: 'SM', value: '378', label: 'San Marino (+378)' },
        { countryCode: 'ST', value: '239', label: 'Sao Tome & Principe (+239)' },
        { countryCode: 'SA', value: '966', label: 'Saudi Arabia (+966)' },
        { countryCode: 'SN', value: '221', label: 'Senegal (+221)' },
        { countryCode: 'CS', value: '381', label: 'Serbia (+381)' },
        { countryCode: 'SC', value: '248', label: 'Seychelles (+248)' },
        { countryCode: 'SL', value: '232', label: 'Sierra Leone (+232)' },
        { countryCode: 'SG', value: '65', label: 'Singapore (+65)' },
        { countryCode: 'SX', value: '1', label: 'Sint Maarten (+1)' },
        { countryCode: 'SK', value: '421', label: 'Slovak Republic (+421)' },
        { countryCode: 'SI', value: '386', label: 'Slovenia (+386)' },
        { countryCode: 'SB', value: '677', label: 'Solomon Islands (+677)' },
        { countryCode: 'SO', value: '252', label: 'Somalia (+252)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'ES', value: '34', label: 'Spain (+34)' },
        { countryCode: 'LK', value: '94', label: 'Sri Lanka (+94)' },
        { countryCode: 'SH', value: '290', label: 'St. Helena (+290)' },
        { countryCode: 'KN', value: '1869', label: 'St. Kitts (+1869)' },
        { countryCode: 'SC', value: '1758', label: 'St. Lucia (+1758)' },
        { countryCode: 'SD', value: '249', label: 'Sudan (+249)' },
        { countryCode: 'SR', value: '597', label: 'Suriname (+597)' },
        { countryCode: 'SZ', value: '268', label: 'Swaziland (+268)' },
        { countryCode: 'SE', value: '46', label: 'Sweden (+46)' },
        { countryCode: 'CH', value: '41', label: 'Switzerland (+41)' },
        { countryCode: 'SI', value: '963', label: 'Syria (+963)' },
        { countryCode: 'TW', value: '886', label: 'Taiwan (+886)' },
        { countryCode: 'TJ', value: '7', label: 'Tajikstan (+7)' },
        { countryCode: 'TH', value: '66', label: 'Thailand (+66)' },
        { countryCode: 'TG', value: '228', label: 'Togo (+228)' },
        { countryCode: 'TO', value: '676', label: 'Tonga (+676)' },
        { countryCode: 'TT', value: '1868', label: 'Trinidad & Tobago (+1868)' },
        { countryCode: 'TN', value: '216', label: 'Tunisia (+216)' },
        { countryCode: 'TR', value: '90', label: 'Turkey (+90)' },
        { countryCode: 'TM', value: '7', label: 'Turkmenistan (+7)' },
        { countryCode: 'TM', value: '993', label: 'Turkmenistan (+993)' },
        { countryCode: 'TC', value: '1649', label: 'Turks & Caicos Islands (+1649)' },
        { countryCode: 'TV', value: '688', label: 'Tuvalu (+688)' },
        { countryCode: 'UG', value: '256', label: 'Uganda (+256)' },
        { countryCode: 'UA', value: '380', label: 'Ukraine (+380)' },
        { countryCode: 'AE', value: '971', label: 'United Arab Emirates (+971)' },
        { countryCode: 'GB', value: '44', label: 'United Kingdom (+44)' },
        { countryCode: 'US', value: '1', label: 'United States of America (+1)' },
        { countryCode: 'UY', value: '598', label: 'Uruguay (+598)' },
        { countryCode: 'UZ', value: '7', label: 'Uzbekistan (+7)' },
        { countryCode: 'VU', value: '678', label: 'Vanuatu (+678)' },
        { countryCode: 'VA', value: '379', label: 'Vatican City (+379)' },
        { countryCode: 'VE', value: '58', label: 'Venezuela (+58)' },
        { countryCode: 'VN', value: '84', label: 'Vietnam (+84)' },
        { countryCode: 'VG', value: '84', label: 'Virgin Islands - British (+1284)' },
        { countryCode: 'VI', value: '84', label: 'Virgin Islands - US (+1340)' },
        { countryCode: 'WF', value: '681', label: 'Wallis and Futuna (+681)' },
        { countryCode: 'YE', value: '969', label: 'Yemen (North) (+969)' },
        { countryCode: 'YE', value: '967', label: 'Yemen (South) (+967)' },
        { countryCode: 'ZM', value: '260', label: 'Zambia (+260)' },
        { countryCode: 'ZW', value: '263', label: 'Zimbabwe (+263)' },
      ],
      Products: [],
      Currency: {},
      Currencies: [
        { code: 'GBP', name: 'British Pound' },
        { code: 'USD', name: 'US Dollar' },
        { code: 'EUR', name: 'Euro' },
        { code: 'ZAR', name: 'South African Rand' },
        { code: 'INR', name: 'Indian rupee' },
      ],
      ex: 1,
      textservices: '',
      textexpertise: '',
      texthelpneeded: '',

      showProjectEdit: false,
      showProjectEditYes: false,
      textprojecttitle: '',
      textprojectdescription: '',
      textprojecturl: '',
      textprojectdeck: '',
      textprojectstatus: 'Active',
      textprojectrole: '',

      projectRowIndex: -1,

      helpexpertise: false,

      profileurl: '',
      newhandle: '',
      profileurlpre: `${window.location.origin}/iam/`,

      editUrl: false,

      textdividers: '',
      isEdit: true,
    }
  },
  created() {
    this.getme();
    this.getProducts();
    EventBus.$on("set_profile_avatar", data => {
      this.Me.photo = data.image;
      this.modalI.close();
      this.saveSettings();
    });
  },
  methods: {

    async getme() {
      this.Me = await this.DataGet('/me');
      if (this.Me.handle === '') {
        this.profileurl = `${window.location.origin}/whois/${this.Me._id}`;
      } else {
        this.profileurl = `${window.location.origin}/iam/${this.Me.handle}`;
      }
      

      (this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem({ nested: true }),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History()
      ],
      content: this.Me.description,
      onUpdate: ({ getJSON, getHTML }) => {
        this.json = getJSON();
        // this.html = getHTML();
        this.Me.description = getHTML();
      }
      })),
      this.editor.focus();
      this.getCurrency();
    },

    async getProducts() {
      this.Products = await this.DataGet('/products');
    },

    async getCurrency() {
      this.Currency = await this.DataGetRaw('https://api.exchangeratesapi.io/latest?base=GBP');
      this.setEx();
    },

    setEx() {
      this.ex = this.Currency.rates[this.Me.currency];
    },

    async refreshMe() {
      this.Me = await this.DataGet('/me');
    },

    // image:start
    changeImage() {
      this.modalI = this.$buefy.modal.open({
        parent: this,
        component: ModalImage,
        hasModalCard: true,
        props: {
          callBus: "set_profile_avatar",
          busData: {}
        }
      });
    },
    // image:end

    addText(field, usearr) {
      if (this[field] === '') {
        return;
      }
      usearr.push({ text: this[field] });
      this[field] = '';
    },

    addTextDivider(field, usearr) {
      if (this[field] === '') {
        return;
      }
      const id = new ObjectID();
      const idout = id.str;
      usearr.push({ 
        _id: idout,
        text: this[field],
        time: '',
        alert: false
      });
      this[field] = '';
    },

    removeFrom(index, usearr) {
      usearr.splice(index, 1);
    },

    addProject() {
      /*
      showProjectEdit: false,
      showProjectEditYes: false,
      textprojecttitle: '',
      textprojectdescription: '',
      textprojecturl: '',
      textprojectdeck: '',
      textprojectstatus: 'Active',
      textprojectrole: '',
      */
     const nr = {
       title: this.textprojecttitle,
       description: this.textprojectdescription,
       url: this.textprojecturl,
       deck: this.textprojectdeck,
       status: this.textprojectstatus,
       role: this.textprojectrole,
       showlong: false,
     }
     this.Me.projects.push(nr);
     this.saveSettings();
    },

    editProject(row, index) {
      this.projectRowIndex = index;
      this.showProjectEdit = true;
      this.showProjectEditYes = true;
      this.textprojecttitle = row.title;
      this.textprojectdescription = row.description;
      this.textprojecturl = row.url;
      this.textprojectdeck = row.deck;
      this.textprojectstatus = row.status;
      this.textprojectrole = row.role;
    },

    saveProject() {
      const id = this.projectRowIndex;
      this.Me.projects[id].title = this.textprojecttitle;
      this.Me.projects[id].description = this.textprojectdescription;
      this.Me.projects[id].url= this.textprojecturl;
      this.Me.projects[id].deck = this.textprojectdeck;
      this.Me.projects[id].status = this.textprojectstatus;
      this.Me.projects[id].role = this.textprojectrole;
      this.Me.projects[id].showlong = false;
      this.showProjectEdit = false;
      this.showProjectEditYes = false;
      this.saveSettings();
    },

    cleanurl() {
      const newtext = this.newhandle.replace(/[&\/\\#,+()$~%.'":*?<>{}!@]/g, '');
      this.newhandle = newtext;
    },

    async saveUrl() {
      if (this.newhandle === '') {
        this.$buefy.snackbar.open({ message: "Don't try and be a ding dong. Enter something!", type: "is-warning", position: "is-top" });
        return;
      }
      this.newhandle.toLowerCase();
      const inhdl = this.newhandle;
      if (inhdl !== this.Me.handle) {
        const checkh = await this.DataGet(`/users/handle/${inhdl}`, 'available');
        if (checkh) {

          this.Me.handle = this.newhandle;
          this.profileurl = `${window.location.origin}/iam/${this.Me.handle}`;
          this.editUrl = false;
          this.saveSettings();
        }
      }
    },

    changeVisibility(newval) {
      let message = '';
      if (newval === 'Pending') {
        message = 'Your profile will be submitted for approval and can take up-to 72 hours to approve.';
      } 
      if (newval === 'No') {
        message = 'Your profile will no longer be available to others';
      } 
      this.$buefy.dialog.confirm({
        title: "",
        message: message,
        confirmText: "Submit",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Me.published = newval;
          this.saveSettings();
        }
      });
    },
    changeExpert(newval) {
      let message = '';
      if (newval === 'Pending') {
        message = 'Your profile will be submitted to become an expert in your field. Please make sure your profile is complete before submiting.';
      } 
      if (newval === 'No') {
        message = 'Your expert status will be removed.';
      } 
      this.$buefy.dialog.confirm({
        title: "",
        message: message,
        confirmText: "Submit",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: () => {
          this.Me.expert = newval;
          this.saveSettings();
        }
      });
    },

    async saveSettings() {
      console.log(this.Me);
      localStorage.setItem("demo", this.Me.demo);
      const upd = await this.DataSend('put', '/me', this.Me, '', true);
    },

    async cancelSub() {
      // cancelsubscription
      	this.$buefy.dialog.confirm({
          title: "",
          message:
          "Are you sure you want to <b>cancel</b> this subscription? All your data will be available under the Personal Package you just will not be able to go over the package's limitations",
          confirmText: "Cancel Subscription",
          type: "is-danger",
          hasIcon: true,
          icon: 'exclamation',
          onConfirm: async () => {
            const cansub = await this.DataDelete('/cancelsubscription');
            this.refreshMe();
          }
        });

    },


    
  },
  mounted() {
    if (this.$route.params.page === 'success') {
      	this.$buefy.toast.open({ message: "Refresing your subscription information", position: "is-bottom", type: "is-info" });
    }
    if (this.$route.params.page === 'cancel') {
      	this.$buefy.toast.open({ message: "Your purchase has been cancelled", position: "is-bottom", type: "is-danger" });
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
}
</script>

<style>
.ProseMirror {
  border: 1px solid #CCC;
  min-height: 100px;
  padding: 5px;
}
.ProfileDesc > p{
  margin-bottom: 10px;
}
</style>