<template>
  <section>
    <div class="modal-card">
      <header class="modal-card-head" style="padding: 10px;">
        <p class="modal-card-title">Change avatar</p>
        <div>
          <span class="imgbutton" @click="$refs.file.click()">
            <input
              type="file"
              ref="file"
              @change="uploadImage($event)"
              accept="image/*"
            />
            Select image
          </span>
        </div>
      </header>
      <section class="modal-card-body">
        <Cropper
          class="cropper"
          :src="image"
          :stencilComponent="$options.components.CircleStencil"
          ref="cropper"
          @change="processImg"
        />
        <div style="text-align: center; margin-top: 15px;">
          <img :src="image2" class="thumb thumb-xlg thumb-circle" />
        </div>
      </section>
      <footer class="modal-card-foot" style="padding: 10px;">
        <button class="button" type="button" @click="$parent.close()">
          Cancel
        </button>
        <button class="button" type="button" @click="submitForm()">
          Set Avatar
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
import { EventBus } from "../event-bus.js";
import { Cropper, CircleStencil } from "vue-advanced-cropper";

export default {
  name: "ModalImage",
  props: {
    callBus: String,
    busData: Object
  },
  components: {
    Cropper,
    CircleStencil
  },
  data() {
    return {
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      image: "/dummy/120x120.png",
      image2: null,
      imageb64: null
    };
  },
  created() {},
  methods: {
    defaultSize() {
      return {
        width: 200,
        height: 200
      };
    },
    uploadImage(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    resizedataURL(datas, wantedWidth, wantedHeight) {
      return new Promise(async function(resolve, reject) {
        // We create an image to receive the Data URI
        var img = document.createElement("img");

        // When the event "onload" is triggered we can resize the image.
        img.onload = function() {
          // We create a canvas and get its context.
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          // We set the dimensions at the wanted size.
          canvas.width = wantedWidth;
          canvas.height = wantedHeight;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

          var dataURI = canvas.toDataURL("image/jpeg", 0.8);

          // This is the return of the Promise
          resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;
      });
    }, // Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

    async processImg() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      const newimage = canvas.toDataURL();
      this.image2 = await this.resizedataURL(newimage, 200, 200);
    },

    async submitForm() {
      this.busData["image"] = this.image2;
      const retdata = this.busData;
      // retdata['fields'] = this.fields;
      EventBus.$emit(this.callBus, retdata);
    }
  }
};
</script>

<style>
.cropper {
  border: solid 1px #eee;
  height: 400px;
  width: 100%;
  background: #ddd;
}

.imgbutton-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.imgbutton {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #3fb37f;
  cursor: pointer;
  transition: background 0.5s;
}

.imgbutton:hover {
  background: #38d890;
}

.imgbutton input {
  display: none;
}
</style>
